import request from "@/utils/request";

class Apis {
  /**
   * 搜索家谱
   */
  searchMaterial(data) {
    return request({
      method: "POST",
      url: "material/index",
      data
    });
  }

  menuList(data) {
    return request({
      method: "POST",
      url: "material/mululist",
      data
    });
  }

  /**
   * 搜索家谱
   */
  myMaterial(data) {
    return request({
      method: "POST",
      url: "material/mylist",
      data
    });
  }

  /**
   * 搜索家谱
   */
  add(data) {
    return request({
      method: "POST",
      url: "material/add",
      data
    });
  }

  /**
   * 搜索家谱
   */
  materialDetail(data) {
    return request({
      method: "POST",
      url: "material/detail",
      data
    });
  }

  /**
   * 搜索家谱
   */
  myMaterialDetail(data) {
    return request({
      method: "POST",
      url: "material/mydetail",
      data
    });
  }

  /**
   * 搜索家谱
   */
  watchedmMterial(data) {
    return request({
      method: "POST",
      url: "material/viewlist",
      data
    });
  }

  /**
   * 搜索家谱
   */
  materialDetailPdf(data) {
    return request({
      method: "POST",
      url: "material/detail_pdf",
      data
    });
  }
}

export default new Apis();
