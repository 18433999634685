<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    width="90%"
    custom-class="modal-container"
  >
    <div slot="title" class="title">
      <span class="info">
        {{ title }}
      </span>
      <img
        alt="icon"
        class="icon"
        src="@/assets/images/login-dialog/img_64028_0_0.png"
        @click="visible = false"
      />
    </div>
    <div class="content">
      <slot />
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";

export default {
  name: "TipsDialog",
  props: {
    title: {
      type: String,
      default: "提示"
    }
  },
  components: {
    "el-dialog": Dialog
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      // this.searchForm = initForm();
      this.visible = true;
    },
    hide() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.form-input {
  .el-input__inner {
    color: #CA0A15;
    font-size: 14px;
    border: 1px solid #f0f0f0;
  }
}
</style>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  align-items: center;
  background: #f7f7f7;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  width: 100%;

  .info {
    color: #333;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 48px;
    text-decoration: none;
    font-weight: bold;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px;
}
</style>
